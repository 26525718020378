.scroll-down-icon {
  position: absolute;
  z-index: 30;
  bottom: 10px;
  right: 10px;
  width: 23px;
}

#scrollPath2 {
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-13px);
  }
}