
.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  background: #ecd7d0;
}

.top-spacing {
  margin-top: 1rem;
}

section {
  height: 100vh;
  width: 100vw;
}

.our-photo-container {
  position: relative;
  padding-top: 7rem;
  height: calc(100vh - 7rem );
  background: #5E6572;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #454545;
  font-size: 1rem;
  overflow: hidden;
}

#initialTextContent {
  color: rgb(241, 241, 241);
  padding: 1rem;
  border-radius: .5rem;
  z-index: 2;
  margin-top: 1rem;
  opacity: 0;
  animation: fadein 3s linear forwards;
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
} 

#initialTextContent > a {
  color: #E5BDA7;
}

#ourPhotoMain {
  position: fixed;
  width: 300px;
  height: 412px;
  left: 50%;
  margin-left: -120px;
  bottom: 0;
}

.small-screen-svg {
  display: none;
}

/* Just to chnage Wave SVG */ 
@media only screen and (max-width: 600px) {
  .small-screen-svg {
    display: block;
  }

  .large-screen-svg {
    display: none;
  }
}


@media only screen and (max-height: 800px) {
  .our-photo-container {
    padding-top: 5em;
    height: calc(100vh - 5em);
  }

  #ourPhotoMain {
    width: 200px;
    height: 275px;
    margin-left: -90px;
  }
}

@media only screen and (max-height: 600px) {
  .our-photo-container {
    padding-top: 1em;
    height: calc(100vh - 1rem);
  }

  #ourPhotoMain {
    width: 170px;
    height: 233px;
    margin-left: -68px;
  }

  #initialTextContent {
    margin-top: -1rem;
  }
}

a {
  color: #176277;
  text-decoration: none;
}


.wave-container {
  position: absolute;
  bottom: -2px;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.wave-container > svg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 56%;
  min-width: 100%;
}

.cloud {
  fill: #ffffff;
  height: auto;
}

.balloon {
  position: fixed;
  z-index: 25;
  transform: translateY(-100%);
  top: 0;
  left: 50%;
  width: 300px;
  height: 507px;
  margin-left: -150px;
}

@media only screen and (max-height: 800px) {
  .balloon {
    width: 250px;
    height: 423px;
    margin-left: -125px;
  }
}

@media only screen and (max-height: 600px) {
  .balloon {
    width: 200px;
    height: 338px;
    margin-left: -100px;
  }
}

.ticket {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 250px;
  height: 390px;
  margin-left: -125px;
  z-index: 5;
}

.small-balloon {
  width: 40px;
}

#balloonsContainer {
  opacity: 0;
  width: 100%;
  height: 600vh;
  position: fixed;
  z-index: 1;
  overflow: hidden;
  top: 0;
  left: 0;
}

.plane-msg {
  width: 500px;
  position: fixed;
  top: 70%;
  left: -500px;
  z-index: 30;
}

@media only screen and (max-height: 800px) {
  .plane-msg {
    top: 60%
  }
}


#footnote {
  background: #f9f9f9;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0px;
  left: 0;
  z-index: 50;
  font-size: 0.9rem;
  padding-bottom: 20px;
  padding-top: 10px;
}

@media only screen and (max-height: 800px) {
  #footnote {
    font-size: 0.7rem;
  }
}

#footnote > a {
  color: #AE1914;
}

#upArrow {
  position: absolute;
  right: 10px;
  bottom: 40px;
  width: 20px;
  background: #AE1914;
  z-index: 50;
  padding: 10px;
  color: #e7e7e7;
  font-weight: bold;
  cursor: pointer;
}

#upArrow > svg {
  width: 100%;
  fill: #cccccc;
}